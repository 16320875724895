import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import PageSpinner from "./PageSpinner";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { toast, ToastContainer } from "react-toastify";
import NotFound from "./NotFound";
import PlanDetails from "./views/plandetails";
import { changePassword } from "actions/users";

import SocketContext from "./components/Context/SocketContext";
import { socket } from "./config/socketConnectivity";

const Home = React.lazy(() => import("views/home.js"));
const Login = React.lazy(() => import("views/login.js"));
const Register = React.lazy(() => import("views/register.js"));
const Login1 = React.lazy(() => import("views/login1.js"));
const ChangePassword = React.lazy(() => import("views/Changepassword.js"));
const ChangePassword1 = React.lazy(() => import("views/Changepassword.js"));
const Forgot = React.lazy(() => import("views/forgot.js"));
const Dashboard = React.lazy(() => import("views/dashboard.js"));
const Withdraw = React.lazy(() => import("views/withdraw.js"));
const Deposit = React.lazy(() => import("views/deposit.js"));
const Settings = React.lazy(() => import("views/settings.js"));
const SupportTicket = React.lazy(() => import("views/support-ticket.js"));
const BuyCrypto = React.lazy(() => import("views/buy-crypto.js"));
const BuyFiat = React.lazy(() => import("views/buy-fiat.js"));
const Profile = React.lazy(() => import("views/profile.js"));
const KYC = React.lazy(() => import("views/kyc.js"));
const Privacy = React.lazy(() => import("views/privacy.js"));
const Terms = React.lazy(() => import("views/terms.js"));
const Disclaimer = React.lazy(() => import("views/disclaimer.js"));
const Referal = React.lazy(() => import("views/referal.js"));
const SiteDown = React.lazy(() => import("views/sitedown"));
const FiatDeposit = React.lazy(() => import("views/fiat-deposit"));
//const NotFound        = React.lazy(() =>import ( "./NotFound"));

ReactDOM.render(
  <BrowserRouter basename="/">
    <SocketContext.Provider value={{ socket }}>
      <ToastContainer />
      <Switch>
        <React.Suspense fallback={<PageSpinner />}>
          <PrivateRoute exact path="/referal" component={Referal} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/buy-crypto" component={BuyCrypto} />
          {/* <PrivateRoute
            exact
            path="/support-ticket"
            component={SupportTicket}
          /> */}
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/withdraw" component={Withdraw} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/planDetails" component={PlanDetails} />
          {/* <Route exact path="/buy-fiat" component={NotFound} /> */}

          <Route exact path="/fiat-deposit" component={FiatDeposit} />
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/home/:authToken" component={Home} />
          <Route exact path="/privacy" component={Privacy} />
          <PrivateRoute exact path="/sitedown" component={SiteDown} />
          <Route exact path="/terms-of-use" component={Terms} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          {/* <Route exact path="/forgot" component={Forgot} /> */}
          {/* <Route exact path="/Changepassword/:userId" component={ChangePassword} /> */}
          <Route exact path="/withdraw/:userId" component={Withdraw} />
          {/* <Route exact path="/register" component={Register} /> */}
          {/* <Route exact path="/login/:authToken" component={Login} /> */}

          {/* <Route exact path="/login" component={Login} />
      <Route exact path="/login1" component={Login1} /> */}
          <Route exact path="/deposit" component={Deposit} />
          <Route exact path="/buy-fiat" component={BuyFiat} />
          {/* <Route exact path="/kyc" component={KYC} /> */}
          <Route exact path="/activate/:userId" component={Login} />
          <Route exact path="/TronWithdraw/:userId" component={Home} />
        </React.Suspense>
      </Switch>
    </SocketContext.Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
