import axios from "axios";
import config from "../lib/config";
import { SET_CURRENT_USER } from "../constant";

export const getrefererdData = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getrefererdData/` + id,
    });
    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsdDepositData = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getsdDepositData/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const confirmWithdraw = async (data) => {
  try {
    console.log("addusers", config.API);
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/confirmWithdraw`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: true,
      message: respData.data.message,

    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const adduser = async (data) => {
  try {
    console.log("addusers", config.API);
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/adduser`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      // userValue: respData.data.userValue
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addNewsLetter = async (data) => {
  try {
    console.log("addusers", config.API);
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addNewsLetter`,
      data: data,
    });
    console.log(respData, "respData.data------->");
    return {
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const ReSendmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/resendmail`,
      data: data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsupportdata = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getsupportdata/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const gethistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/gethistory/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateSupport = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/updateSupport`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const activateuser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/activateuser`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addcontact = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addcontact`,
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaq = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getfaq/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      faqData: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTimerDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getTimer/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      status: respData.status,
      timerData: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/login`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);

    return {
      loading: false,
      id: respData.data.id,
      result: respData.data.result,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const ICOLOGIN = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/exchangelogin`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);

    return {
      loading: false,
      id: respData.data.id,
      result: respData.data.result,
      status: true,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const verfyOtp = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/verfiyOtp`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logout = (history) => {
  localStorage.removeItem("user_token");
  history.push("/Login");
};
export const profile = () => {
  localStorage.getItem("user_token");
};
export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/users/changePassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePasswordOld = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/users/changePasswordOld`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const savereply = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/savereply`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

//update2faCode
export const get2faCode = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/get2faCode`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const update2faCode = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/users/get2faCode`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const Disabled2Fa = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/Disabled2Fa`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData.data.messages,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePasswordotp = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/users/forgotPassword`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateuser = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.fname);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phonenumber", data.phoneNumber);
    bodyFormData.append("state", data.state);
    bodyFormData.append("address", data.address);
    bodyFormData.append("country", data.country);
    bodyFormData.append("address2", data.state);
    bodyFormData.append("city", data.city);
    bodyFormData.append("pincode", data.pcode);
    bodyFormData.append("lastname", data.lname);
    bodyFormData.append("Photofile", data.image);
    bodyFormData.append("dbEmail", data.dbEmail);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/updateProfile/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addsddeposit = async (data) => {
  try {
    console.log("data Information add deposit", data);
    let bodyFormData = new FormData();
    bodyFormData.append("Transactionid", data.Transactionid);
    bodyFormData.append("amounttype", data.amounttype);
    bodyFormData.append("bankAccNo", data.bankAccNo);
    bodyFormData.append("imageValue", data.imageValue);
    bodyFormData.append("sdDepositAmount", data.sdDepositAmount);
    bodyFormData.append("oneYearPlan", data.oneYearPlan);
    bodyFormData.append("twoYearPlan", data.twoYearPlan);
    bodyFormData.append("sdDepositType", data.sdDepositType);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/standardDeposit/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });

    return {
      loading: true,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const upiaddsddeposit = async (data) => {
  try {
    console.log("data Information add deposit", data);
    let bodyFormData = new FormData();
    bodyFormData.append("upiTransactionid", data.upitransactionid);
    bodyFormData.append("upiamounttype", data.upiamounttype);
    bodyFormData.append("upiimageValue", data.upiimageValue);
    bodyFormData.append("upiDepositAmount", data.upiamount);
    bodyFormData.append("oneYearPlan", data.oneYearPlanupi);
    bodyFormData.append("twoYearPlan", data.twoYearPlanupi);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/upiDeposit/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });

    return {
      loading: true,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateuser_emailotp = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.fname);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phonenumber", data.phoneNumber);
    bodyFormData.append("state", data.state);
    bodyFormData.append("address", data.address);
    bodyFormData.append("country", data.country);
    bodyFormData.append("address2", data.state);
    bodyFormData.append("city", data.city);
    bodyFormData.append("pincode", data.pcode);
    bodyFormData.append("lastname", data.lname);
    bodyFormData.append("Photofile", data.image);
    bodyFormData.append("dbEmail", data.dbEmail);
    bodyFormData.append("emailOtp", data.emailOtp);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/updateuser_emailotp`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });
    console.log("resdatadata", respData);
    return {
      loading: false,
      result: respData.data.messages,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const kyc = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("addressproof", data.addressproof);
    bodyFormData.append("idproofback", data.idproofback);
    bodyFormData.append("idprooffrond", data.idprooffrond);
    bodyFormData.append("idwithselfie", data.idwithselfie);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/kycupdate/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
      userValue: respData.data.successmessage,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuser = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getuser/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    console.log("response withdraw userData", respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};

export const getsddDepositData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getsddDepositData/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    console.log("response withdraw userData", respData.data.userValue);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};

export const getWallet = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getWallet/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};

export const getReferral = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getReferral/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
      userInfo: respData.data.userInfo,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};

export const getadmin = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getadmin/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getFiat = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getFiat/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      fiatValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getFiatdetails = async (userid, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getFiatdetails/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      fiatValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const gettransation = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/gettransation/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTicketData = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getTicketData/` + id,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getkyc = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getkyc/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatekyc = async (data, dispatch) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("proof", data.idprooffrond);
    //  bodyFormData.append('selfi', data.selfi);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/updatekyc/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updateWithdrawProcess = async (data) => {
  try {
    // let bodyFormData = new FormData();
    // bodyFormData.append('proof', data.idprooffrond);
    //  bodyFormData.append('selfi', data.selfi);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/withdrawUsers/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    console.log("resdataresdata", respData);
    return {
      loading: false,
      userValue: respData.data.userValue,
      message: respData.data.message,
    };
  } catch (err) {
    alert(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sendOtpm = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/sendOtpm/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.successmessage,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const confirmOtp = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/confirmOtp/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.successmessage,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addTronbuy = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addTronbuy/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addcontactus = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addcontactus/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addEthbuy = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addEthbuy/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addFiatbuy = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addFiatbuy/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSupportDetails = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getSupportDetails`,
    });

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTicketList = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getTicketList/` + id,
    });
    return {
      loading: false,
      ticketValue: respData.data.ticketValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const supportTicketDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/supportTicketDetails/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: true,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getDetailsOfSupport = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getDetailsOfSupport/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
      supportcount: respData.data.supportcount,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const userdescription = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/userdescription/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });

    return {
      loading: true,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const closeTicket = async (id) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/closeTicket/` + id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getChats = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getChats/` + id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      loading: false,
      // ticketValue: respData.data.ticketDatas.reply,
      ticketValue: respData.data.ticketDatas,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const withdraw = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/withdraw/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    console.log("err~~~~~~~~~~~~~~~~~~", err);

    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getEthtransaction = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getEthtransaction/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getEthwithdraw = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getWallettransaction/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      success: respData.data.success,
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCmsList = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getCmsList`,
    });
    console.log("resdataformcms", respData.data.yearwise);
    return {
      loading: false,
      cmsData: respData.data.cmsData,
      Big_Data_Insight: respData.data.Big_Data_Insight,
      Easy_To_Use: respData.data.Easy_To_Use,
      cmsSecure: respData.data.cmsSecure,
      whyCms: respData.data.whyCms,
      Most_Credibility: respData.data.Most_Credibility,
      RoadMap: respData.data.RoadMap,
      yearwise: respData.data.yearwise,
      slidewiset: respData.data.slidewiset,
      imageteam: respData.data.imageteam,
      Token_Distribution: respData.data.Token_Distribution,
      mudra_token_cms: respData.data.mudra_token_cms
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCompanyLogo = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getCompanyLogo`,
    });

    return {
      loading: false,
      CompanyLogoData: respData.data.CompanyLogoData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const get_UpdateWallet = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addWallet/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    console.log("respDatarespDatarespData", respData);
    return {
      loading: false,
      status: respData.data.success,
      message: respData.data.message,
      userData: respData.data.userData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const get_WithdrawHistory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/historyWithdraw/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    console.log("respDatarespDatarespDataaaaaa", respData);
    return {
      loading: false,
      status: respData.data.success,
      message: respData.data.message,
      userData: respData.data.returnWithdraw,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCryptoCompareApi = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getCryptoAmount/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    console.log("respDatarespDatarespDataaaaaa", respData);
    return {
      loading: false,
      status: respData.data.success,
      data: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const get_UpdateTransaction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/addTransaction/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    console.log("respDatarespDatarespData", respData);
    return {
      loading: false,
      statuss: respData.data.success,
      messagee: respData.data.message,
      userData: respData.data.userData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getFiatBalance = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getinrbalance/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      data: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};


export const updateUserDetails = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/updateUserDetails/`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      data: respData.data,
    };
  } catch (err) {
    return {
      loading: false,

    };
  }
};


export const getExchnageBalanceApi = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/users/getExchnageBalance/`,
      headers: {
        Authorization: localStorage.user_token,
      },

    });
    return {
      loading: false,
      data: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error:
        err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : "",
    };
  }
};


export const buyFromMudraWallet = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/users/buyFromMudraWallet/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    return {
      status: true,
      loading: false,
      data: respData.data.message,
    };
  } catch (err) {
    // console.log("bbbbbbbbbbbbb",err.response);
    return {
      status: false,
      loading: false,
      error:
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "",
    };
  }
};
export const transeferFoundtoExchnage = async (data) => {
  try {
    // let bodyFormData = new FormData();
    // bodyFormData.append('proof', data.idprooffrond);
    //  bodyFormData.append('selfi', data.selfi);

    let respData = await axios({
      method: "post",
      url: `${config.API}/users/transeferFoundtoExchnage/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    console.log("resdataresdata", respData);
    return {
      loading: false,
      userValue: respData.data.userValue,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

