import React,{useEffect,useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Favorite from "@material-ui/icons/Favorite";
import ReactHtmlParser from 'react-html-parser';
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";
import {getCmsList} from '../../actions/users';


const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const [cmsdata, setcmsdata] = useState([]);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  useEffect(() => {

    //getcmslist();
   
  }, [])
  const getcmslist = async () => {

    const { cmsData } = await getCmsList();
    setcmsdata(cmsData);
  }
  return (
    <footer className={footerClasses + " footer_inner"}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem sm={4} md={4} lg={4}>
          
                       {/* {
                         cmsdata && cmsdata.map((data,i)=>{
                          return (
                            <>
                            {
                              data.identifier =="AboutDJackToken" &&
                              <>
                                <h2>About Mudera Token </h2>                              {
                                ReactHtmlParser(
                                  data.content
                                )}
                              </>
                            }
                             
                            </>
                          )

                         })
                       }   */}
                  <h2>About Mudera Token </h2>       
            <p>Mudra Exchange will launch its own cryptocurrency known as ‘Mudra’. The Mudra Token will be a utility token that can be used in exchange for certain services in future. Token is Built around community corresponding to the main aim of the platform i.e, Financial inclusion in Indian cryptocurrency market and increasing Indian Users in the Cryptocurrency World.</p>
          </GridItem>
          <GridItem sm={4} md={4} lg={4}>
            <h2>Quick Links</h2>
            <ul className="quick_links">
              <li><Link to="/profile">Profile</Link></li>
              <li><Link to="/support-ticket">Support Ticket</Link></li>
              <li><Link to="/privacy" target="_blank">Privacy Policy</Link></li>
              <li><Link to="/terms" target="_blank">Rules and Terms</Link></li>
            </ul>
          </GridItem>
          {/* <GridItem sm={4} md={4} lg={2}>
            <h2>Payment Option</h2>
            <div className="mb-3"><img src={require("../../assets/images/paypal_icon.png")} alt="Icon" className="img-fluid" /> </div>
             <div><img src={require("../../assets/images/tron_link_footer_icon.png")} alt="Icon" className="img-fluid" /></div>
          </GridItem> */}
          <GridItem sm={4} md={4} lg={4}>
            <img src={require("../../assets/images/logomudra.png")} alt="Icon" className="img-fluid withdraw-img" />

            <div className="copyright_txt">All Rights Reserved <Link to="/" className={aClasses}>Mudra Exchange</Link>
              {" "}Online{" "}{1900 + new Date().getYear()}{" "}
            </div>
            <ul className="social_links">
            <div className="icon_social _only_footer">
                    <ul>
                  <li><i class="fab fa-facebook-f"></i></li>
                  <li><i class="fab fa-twitter"></i></li>
                  <li><i class="fab fa-linkedin-in"></i></li>
                  <li><i class="fab fa-instagram"></i></li>
                  <li><i class="fab fa-youtube"></i></li>
                  </ul>  
                </div>
            </ul>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
