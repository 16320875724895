import React,{useState,useEffect} from "react";
import { Link, NavLink,useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Button, Hidden } from '@material-ui/core';
import { toast } from 'react-toastify';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import config from "../../lib/config"

import {encryptString,decryptString} from "../../lib/cryptojs"


import CryptoJS from "crypto-js"

const useStyles = makeStyles(styles);
let toasterOption = { 
  position: "top-right",  
  autoClose: 5000,  
  hideProgressBar: false, 
  closeOnClick: true, 
  pauseOnHover: true, 
  draggable: true,  
  progress: undefined,  
} 


export default function HeaderLinksAfterlogin(props) {

  const classes = useStyles();
  const history = useHistory();

  async function logout (){
  
     toast.success("Logout Successfully", toasterOption); 
    localStorage.setItem('ljmkasdh','no');
    history.push("/login")

    localStorage.clear()
  
  }
  const NavigateExchange = async (e) => {

    var jwttoken = localStorage.getItem('user_token')

    if (jwttoken) {


      var encryptuserdata =  await encryptString(jwttoken,true)



      window.location = config.exchangeurl;

      // window.open(config.exchangeurl+encryptuserdata)

  }
  }

  return (
    <div className="top_navbar">
      <List className={classes.list}>
        {/* <ListItem className={classes.listItem}>
          <a href="https://www.doublejack.club/" target="_blank" color="transparent" className="nav-link">
            <Button variant="contained" className="menu_btn">Club</Button>
          </a>
        </ListItem> */}
        <ListItem className={classes.listItem}>
            <NavLink to="/dashboard" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Dashboard</Button>
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
              <Button onClick={NavigateExchange} variant="contained" className="menu_btn">Exchange</Button>
          </ListItem>
         
          <ListItem className={classes.listItem}>
            <NavLink to="/fiat-deposit" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn"> Fiat-Deposit</Button>
            </NavLink>
          </ListItem>
        
          <ListItem className={classes.listItem}>
            <NavLink to="/withdraw" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Withdraw</Button>
            </NavLink>
          </ListItem>
          {/* <ListItem className={classes.listItem}>
            <NavLink to="/kyc" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">kyc</Button>
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink to="/settings" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Support</Button>
            </NavLink>
          </ListItem> */}
          {/* {localStorage.getItem('ljmkasdh')=="yes"?
          <ListItem className={classes.listItem}>
            <NavLink to="/login" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn" onClick={()=>{logout()}}>Logout</Button>
            </NavLink>
          </ListItem>
     :
      <ListItem className={classes.listItem}>
            <NavLink to="/login" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn" >Login</Button>
            </NavLink>
          </ListItem>
          } */}

        {/* <Hidden mdUp>
          <ListItem className={classes.listItem}>
            <NavLink to="/dashboard" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Dashboard</Button>
            </NavLink>
          </ListItem>
        
          <ListItem className={classes.listItem}>
            <NavLink to="/withdraw" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Withdraw</Button>
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink to="/settings" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn">Settings</Button>
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink to="/login" color="transparent" className="nav-link">
              <Button variant="contained" className="menu_btn" onClick={()=>{logout()}}>Logout</Button>
            </NavLink>
          </ListItem>
        </Hidden> */}
      </List>
    </div>
  );
}
