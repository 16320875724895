import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import isLogin from "./isLogin";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isLogin() === "yes" ? (
                <Component {...props} />
            ) : (
                <Redirect to="/home" />
            )
        }
    />
);


export default PrivateRoute;
