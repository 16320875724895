// export default function isLogin(props) {
//   try{
//     var getLogin = localStorage.getItem('ljmkasdh');
//     return getLogin;
//   }catch(err){
//     return "no";
//   }
  
// }

export default function isLogin(props) {
 
  try{
    if (localStorage.user_token) {
      return "yes";

    }else{
      return "no";

    }
  }catch(err){
    return "no";
  }
  
}
