let key = {};
if (process.env.REACT_APP_MODE === "production") {
  //live url
  // const Tronimg      = 'https://mudra.exchange/src/assets/images/wb_01.png';
  // const EThimg       = 'https://mudra.exchange/wb_02.png';
  // const API_URL1     = 'https://mudra.exchange';
  // const API_URL      = 'https://mudra.exchange';
  // const Back_URL     = 'https://mudra.exchange/images/kyc/';
  // const Back_URL1    = 'https://mudra.exchange/images/user/';
  // const No_Img_URL   = 'https://mudra.exchange/images/noimage.png';
  const Tronimg = "https://producationicoapi.mudra.exchange/src/assets/images/wb_01.png";
  const EThimg = "https://producationicoapi.mudra.exchange/wb_02.png";
  const API_URL1 = "https://producationicoapi.mudra.exchange";
  const API_URL = "https://producationicoapi.mudra.exchange";
  const Back_URL = "https://producationicoapi.mudra.exchange/images/kyc/";
  const Back_URL1 = "https://producationicoapi.mudra.exchange/images/user/";
  const No_Img_URL = "https://producationicoapi.mudra.exchange/images/noimage.png";

  const contractAddr = "41720f5e6e88fea043841524f452ef0315d64ae3d0";
  const smartcontract = "TLNJSmwvFTU8PxyWenpK5Tu6fy9n7r6sAn";
  const owneraddr = "TFeB1pDo8CFEXRP9GcMtzfkJ7cRWE8BbvN";
  const EthOwnerAddr = "0xe046F17D5638541b92CCd52DBE39B17D13196303";

  // const fullHost="https://mudra.exchange";
  const fullHost = "https://producationicoapi.mudra.exchange";
  const networkid = 1;
  const symbols = "ETH";
  const exchangeurl = "https://mudra.exchange/login/";
  const exchangeRoute = "https://mudra.exchange/";
  const exchangeApi = "https://api.mudra.exchange/";
  key = {
    secretOrKey: "test",
    cryptoSecretKey: "1234567812345678",
    Recaptchakey: "", //local
    Back_URL: Back_URL,
    Tronimg: Tronimg,
    EThimg: EThimg,
    No_Img_URL: No_Img_URL,
    Back_URL1: Back_URL1,
    API: `${API_URL}`,
    API_URL1: API_URL1,
    fullHost: fullHost,
    smartcontract: smartcontract,
    owneraddr: owneraddr,
    contractAddr: contractAddr,
    EthOwnerAddr: EthOwnerAddr,
    networkId: networkid,
    symbol: symbols,
    exchangeurl: exchangeurl,
    exchangeRoute: exchangeRoute, 
    exchangeApi: exchangeApi,
    SOCKET_URL: "https://producationicoapi.mudra.exchange",
    USDT_TOKEN_CONTRACT_ADDRESS:"0xdac17f958d2ee523a2206206994597c13d831ec7",
    ADMIN_ADDRESS:"0xAC68c7d3536382c0fE0109Db924346dafFF217fD"
  };
} else if (process.env.REACT_APP_MODE === "demo") {

  const Tronimg = "https://mudraicoapi.wealwin.com/src/assets/images/wb_01.png";
  const EThimg = "https://mudraicoapi.wealwin.com/wb_02.png";
  const API_URL1 = "https://mudraicoapi.wealwin.com";
  const API_URL = "https://mudraicoapi.wealwin.com";
  const Back_URL = "https://mudraicoapi.wealwin.com/images/kyc/";
  const Back_URL1 = "https://mudraicoapi.wealwin.com/images/user/";
  const No_Img_URL = "https://mudraicoapi.wealwin.com/images/noimage.png";

  const contractAddr = "41720f5e6e88fea043841524f452ef0315d64ae3d0";
  const smartcontract = "TLNJSmwvFTU8PxyWenpK5Tu6fy9n7r6sAn";
  const owneraddr = "TFeB1pDo8CFEXRP9GcMtzfkJ7cRWE8BbvN";
  const EthOwnerAddr = "0xe046F17D5638541b92CCd52DBE39B17D13196303";

  // const fullHost="https://mudra.exchange";
  const fullHost = "https://mudraicoapi.wealwin.com";
  const networkid = 5; //1 main 5-goerlie
  const symbols = "ETH";
  const exchangeurl = "https://mudra.wealwin.com/login";
  const exchangeRoute = "https://mudra.wealwin.com/";
  const exchangeApi = "http://mudraapi.wealwin.com/";
  key = {
    secretOrKey: "test",
    cryptoSecretKey: "1234567812345678",
    Recaptchakey: "", //local
    Back_URL: Back_URL,
    Tronimg: Tronimg,
    EThimg: EThimg,
    No_Img_URL: No_Img_URL,
    Back_URL1: Back_URL1,
    API: `${API_URL}`,
    API_URL1: API_URL1,
    fullHost: fullHost,
    smartcontract: smartcontract,
    owneraddr: owneraddr,
    contractAddr: contractAddr,
    EthOwnerAddr: EthOwnerAddr,
    networkId: networkid,
    symbol: symbols,
    exchangeurl: exchangeurl,
    exchangeApi: exchangeApi,
    exchangeRoute: exchangeRoute,
    SOCKET_URL: "https://mudraicoapi.wealwin.com",
    USDT_TOKEN_CONTRACT_ADDRESS:"0xaFF4481D10270F50f203E0763e2597776068CBc5",
    ADMIN_ADDRESS:"0x0EB31F690937CAD54dC68AE9d076B6b8Ba19f4Ad"
  };
} else {
  const Tronimg = "http://localhost:2054/src/assets/images/wb_01.png";
  const EThimg = "http://localhost:2054/wb_02.png";
  const API_URL1 = "http://localhost:3000";
  const API_URL2 = "http://localhost:2054/assets/images/45.gi";

  const adminref = "Djack2021";
  const API_URL = "http://localhost";
  const Back_URL = "http://localhost:2054/images/kyc/";
  const Back_URL1 = "http://localhost:2054/images/user/";
  const No_Img_URL = "http://localhost:2054/images/user/noimage.png";
  const sdimage = "http://localhost:2054/images/sdDeposit/";

  const contractAddr = "41720f5e6e88fea043841524f452ef0315d64ae3d0";
  const smartcontract = "TLNJSmwvFTU8PxyWenpK5Tu6fy9n7r6sAn";
  const owneraddr = "TFeB1pDo8CFEXRP9GcMtzfkJ7cRWE8BbvN";
  const EthOwnerAddr = "0xe046F17D5638541b92CCd52DBE39B17D13196303";
  const networkid = 5;
  const symbols = "ETH";
  const fullHost = "https://api.trongrid.io";
  const exchangeurl = "http://localhost:3000/login/";
  const exchangeRoute = "http://localhost:3000/";
  const exchangeApi = "http://localhost:2053/";

  

  key = {
    secretOrKey: "test",
    cryptoSecretKey: "1234567812345678",
    Recaptchakey: "", //local
    Back_URL: Back_URL,
    Tronimg: Tronimg,
    EThimg: EThimg,
    Back_URL1: Back_URL1,
    No_Img_URL: No_Img_URL,
    adminref: adminref,
    API: `${API_URL}:2054`,
    API_URL1: API_URL1,
    API_URL2: API_URL2,
    fullHost: fullHost,
    smartcontract: smartcontract,
    owneraddr: owneraddr,
    contractAddr: contractAddr,
    EthOwnerAddr: EthOwnerAddr,
    sdimage: sdimage,
    networkId: networkid,
    symbol: symbols,
    exchangeurl: exchangeurl,
    exchangeRoute: exchangeRoute,
    exchangeApi: exchangeApi,
    SOCKET_URL: "http://localhost:2054",
    USDT_TOKEN_CONTRACT_ADDRESS:"0xaFF4481D10270F50f203E0763e2597776068CBc5",
    ADMIN_ADDRESS:"0x0EB31F690937CAD54dC68AE9d076B6b8Ba19f4Ad"
  };
}

export default key;
