import React from "react";
import { Link, NavLink,useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Help, LockOpen, Assignment } from "@material-ui/icons";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import { toast } from 'react-toastify';
const useStyles = makeStyles(styles);
  

let toasterOption = { 
  position: "top-right",  
  autoClose: 5000,  
  hideProgressBar: false, 
  closeOnClick: true, 
  pauseOnHover: true, 
  draggable: true,  
  progress: undefined,  
} 


export default function DashNavbar(props) {
  const classes = useStyles();
  const history = useHistory();

  async function logout (){
  alert("")
     toast.success(" Logout Successfully", toasterOption); 
    localStorage.setItem('ljmkasdh','no');
    history.push("/login")
}
  return (
    <List className={classes.list + " dash_menu"}>
      <ListItem className={classes.listItem}>
        <NavLink to="/dashboard" color="transparent" className="nav-link">
          <Button variant="contained" className="menu_btn">Dashboard</Button>
        </NavLink>
      </ListItem>
     {/* <ListItem className={classes.listItem}>
        <NavLink to="/kyc" color="transparent" className="nav-link">
          <Button variant="contained" className="menu_btn">KYC</Button>
        </NavLink>
      </ListItem>*/}
    
      <ListItem className={classes.listItem}>
        <NavLink to="/withdraw" color="transparent" className="nav-link">
          <Button variant="contained" className="menu_btn">Withdraw</Button>
        </NavLink>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <NavLink to="/settings" color="transparent" className="nav-link">
          <Button variant="contained" className="menu_btn">Settings</Button>
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to="/login" color="transparent" className="nav-link">
          <Button variant="contained" className="menu_btn" onClick={()=>{logout()}}>Logout</Button>
        </NavLink>
      </ListItem>*/}
    </List>
  );
}
