// import package
import io from 'socket.io-client';

// import lib
import config from '../lib/config';
import jwt_decode from "jwt-decode";


const socket = io(config.SOCKET_URL);

const createSocketUser = (userId) => {
    socket.emit('CREATEROOM', userId)
}

socket.on('userAsset', function (data) {
})

socket.on("Logout", function (data) {


    var jwttoken = localStorage.getItem('user_token')

    if (jwttoken) {

        jwttoken = jwttoken.replace('Bearer ', '')


        const decodeduser = jwt_decode(jwttoken);

        var icouserid = decodeduser._id

        var dataremo = data.replace('Bearer ', '')
        var datatoken = jwt_decode(dataremo);

        datatoken = datatoken._id


        if (icouserid.toString() == datatoken.toString()) {
            // alert("yes")
            localStorage.setItem('ljmkasdh','no');
            localStorage.clear()

            window.location.reload(false)
        }
    }

})

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export {
    socket,
    createSocketUser
}