import React, {useEffect,useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import DashNavbar from "components/Navbar/DashNavbar.js";
import  Footerpage from  "components/Footer/Footerpage.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from 'axios';

const useStyles       = makeStyles(styles);
const dashboardRoutes = [];

export default function Terms(props) {
  
  const { ...rest } = props;
  const classes = useStyles();
  const [countryCode,setcountryCode]=useState()
 
  useEffect(() => {
    getapi();
  }, []);

  const getapi=()=>{

    axios
    .get("https://pro.ip-api.com/json?key=VT6Pto4zNt4X0vF")
    .then(response=>{
      const country=response.data.country;
      const country_code=response.data.countryCode;
      setcountryCode(country_code)
    })
  }
  return (
    <div>      
{countryCode=="US"  ?
        <div>
      <div className="login_empty">
      <img src={require("../assets/images/45.gif")} alt="logo" className="img-fluid d-block login-header" />
      <h6>We are sorry. This service is not available in your country.</h6>
     </div>
     </div>
     :
     <div className="header_home">         
        <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logomudra.png")} alt="logo" className="img-fluid withdraw-img" />}
        rightLinks={<HeaderLinksAfterlogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />
      <div className={classes.pageHeader}>
        <div className="dash_top_bar content_page mt-5">
          <div className={classes.container+ " inner_pageheader"}>
            <GridContainer className="mt-5">
              <GridItem md={12} lg={12}>
                <h2> One Year Plan Details</h2>
                <div className="table_section_">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">INR</th>
                      <th scope="col">ROI/Annual</th>
                      <th scope="col">Lock up Period</th>
                      <th scope="col">Reward</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td >1.(a)</td>
                      <td>500-9999</td>
                      <td>40%</td>
                      <td>Lock up For 2 But after 1st year every quarter 25% will unlock(2ndyear ROI will give on the Locked Coin )</td>
                      <td></td>
                    </tr>
                   
                    <tr>
                      <td >2.(a)</td>
                      <td>10,000- 99999</td>
                      <td>45%</td>
                      <td>Lock up For 2 But after 1st year every quarter 25% will unlock(2ndyear ROI will give on the Locked Coin )</td>
                      <td></td>
                    </tr>
                   
                    <tr>
                      <td >3.(a)</td>
                      <td>1,00,000 +</td>
                      <td>50%</td>
                      <td>Lock up For 2 But after 1st year every quarter 25% will unlock(2ndyear ROI will give on the Locked Coin )</td>
                      <td></td>
                    </tr>
                   
                    
                  </tbody>
</table>
<h2> Two Year Plan Details</h2> <br></br>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">INR</th>
                      <th scope="col">ROI/Annual</th>
                      <th scope="col">Lock up Period</th>
                      <th scope="col">Reward</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                    <tr>
                      <td >(1)</td>
                      <td>500-9999</td>
                      <td>40%</td>
                      <td>Lock up For 2 Years Completely(Will Unlock After 2 year)</td>
                      <td>10%</td>
                    </tr>
                   
                    <tr>
                      <td >(2)</td>
                      <td>10,000- 99999</td>
                      <td>45%</td>
                      <td>Lock up For 2 Years Completely(Will Unlock After 2 year)	</td>
                      <td>10%</td>
                    </tr>
                    
                    <tr>
                      <td >(3)</td>
                      <td>1,00,000 +</td>
                      <td>50%</td>
                      <td>Lock up For 2 Years Completely(Will Unlock After 2 year)	</td>
                      <td>15%</td>
                    </tr>
                    
                  </tbody>
</table>
                </div>
                <div className="d-flex justify-content-center">
                <div className="form-group">
                <div className="custom-control custom-checkbox">
                        
                        <input type="checkbox" className="custom-control-input" id="accept" />
                        {/* <label className="custom-control-label text_grey padding_radio_butom" htmlFor="accept">I accept and agree the <a href="/terms"  className="text_red" target="_blank">terms and conditions</a></label> */}
                      </div>
                </div>
                </div>
                
               
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footerpage/>
      </div>
      }
      
    </div>
  );
}
